'use client';

import { IC_ARROWDROPDOWN, IC_NOTIFIED } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import { useAlert } from '@/contexts/AlertProvider';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import usePixalEvent from '@/services/event/usePixalEvent';
import { ProductSummary, checkDeliveryAndStock } from '@/services/utils/helper';
import cls from 'classnames';
import { includes } from 'lodash';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { MdCheckCircle, MdClose } from 'react-icons/md';
import styles from './addToCart.module.scss';
import { Puff } from 'react-loader-spinner';

const checkJsx = (label: any) => {
	if (typeof label === 'string') {
		return label;
	}
	return label;
};
interface Props {
	product: any;
	height?: string;
	label: string | JSX.Element | 'Add to Cart';
	variant?: any;
	inputStyle?:
		| any
		| {
				minWidth: '208px';
		  };
	style?: any;
	buttonStyle?: any;
	outOfStockClass?: any;
	notified?: boolean;
	quantityFromGlobalData?: boolean;
	productRawData?: any;
}

const AddToCart = ({
	product: productData,
	productRawData,
	variant,
	label,
	inputStyle = {
		minWidth: '208px'
	},
	height = '40px',
	style,
	buttonStyle,
	outOfStockClass,
	quantityFromGlobalData,
	notified
}: Props) => {
	const [open, setOpen] = useState(false);
	const [product, setProduct] = useState(productData);
	const [isLoading, setIsLoading] = useState(false);
	const { cartAction, carts, removeCart, allRequestedProducts, setAllRequestedProducts } = useCart();
	const cartRef = useRef<HTMLDivElement>(null);
	const { isLogged }: any = useAuth();

	const { setShowLoginModal } = useLoginModel();
	const { unit_id, variant_id, price, updateQuantityOptions, unit_label } = ProductSummary(variant, setIsLoading);
	// const { unit_id, variant_id, price, updateQuantityOptions } = ProductSummary(variant, globalData ,"test");
	const [isRequestSent, setIsRequestSent] = useState(false);
	const alert = useAlert();
	const toast = useAlert();
	const { EventAddToCart } = usePixalEvent();
	const [positionUpwards, setPositionUpwards] = useState(false);
	const [quantityData, setQuantityData] = useState([]);
	const handleDropdownPosition = () => {
		if (cartRef.current) {
			const dropdownRect = cartRef.current.getBoundingClientRect();
			const spaceBelow = window.innerHeight - dropdownRect.bottom;

			setPositionUpwards(spaceBelow < 250); // Assuming 250px is the minimum required space
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleDropdownPosition);
		return () => {
			window.removeEventListener('resize', handleDropdownPosition);
		};
	}, []);
	const handleOutsideClick = (event: MouseEvent) => {
		if (cartRef.current && !cartRef.current.contains(event.target as Node)) {
			setOpen(false);
			setIsLoading(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	const handleAddToCart = async (quantity: number) => {
		setIsLoading(true);
		const cartActionData = {
			id: product?.id || productRawData.id,
			quantity: quantity,
			variant: variant_id,
			baseUnit: unit_id
		};
		cartAction(cartActionData);
		EventAddToCart({
			currency: 'BDT',
			items: product || productRawData,
			value: Number(price) * quantity,
			qty: quantity,
			content_type: 'product'
		});
		setIsLoading(false);
	};

	const { stock } = checkDeliveryAndStock(product || productRawData, variant);
	// const { stock } = checkDeliveryAndStock(product?._id ? product : productRawData, variant);

	const handleClick = async () => {
		if (!isLogged) {
			setShowLoginModal(true);
			return;
		}
		if (!stock) {
			if (product?.p_id || productRawData?.p_id) {
				const { data, status, message } = await AroggaAPI.post('/general/v1/ProductRequestStock', {
					prs_product_id: product?.p_id || productRawData?.p_id,
					prs_product_variant_id: variant_id
				});

				if (status === 'success') {
					setIsRequestSent(true);
					alert.success({
						title: 'Success',
						message: 'As requested, you will receive notification once comes in stock',
						provider: 'toast'
					});
					setAllRequestedProducts([...allRequestedProducts, variant_id]);
				} else {
					setIsRequestSent(false);
					alert.error({
						message: message ? message : 'Something went wrong!',
						provider: 'toast'
					});
				}
			}
		}
	};
	const isAlreadyInCert = carts?.products?.find((item: any) => item.pv?.id === variant_id);
	const buttonLabel: any = isAlreadyInCert?.qty ? (
		`${unit_label}`
	) : (
		<Tooltip
			style={{
				backgroundColor: 'white',
				color: 'black',
				fontSize: '12px',
				padding: '10px',
				borderRadius: '10px',
				border: '1px solid #ccc',
				boxShadow: '0px 0px 10px 0px #ccc',
				maxWidth: '150px'
			}}
			content='Cold Items Currently Unavailable for Outside Dhaka'
			placement='top'>
			<span className='text-primary'>Qty: 0</span>
		</Tooltip>
	);
	const checkIsRequested = includes(allRequestedProducts, variant_id);

	const BtnLoadingProps = {
		loading: isLoading,
		loadingIcon: (
			<Puff
				visible={true}
				height='24'
				width='24'
				color='#4fa94d'
				ariaLabel='puff-loading'
				wrapperStyle={{
					paddingInline: '10px'
				}}
				wrapperClass=''
			/>
		)
	};

	useEffect(() => {
		if (open) {
			setIsLoading(true); // Set loading to true before async operation
			updateQuantityOptions()
				.then((fetchedData) => {
					setIsLoading(false); // Set loading to false after async operation
					if (fetchedData === false) {
						setProduct((prev) => {
							return {
								...prev,
								p_allow_sales: 0
							};
						});
						toast.error({
							message: 'Stock is not available',
							provider: 'toast'
						});
						setOpen(false);
					} else {
						setQuantityData(fetchedData);
					}
				})
				.catch((error) => {
					console.error('Error fetching quantity options:', error);
					setOpen(false);
					setIsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<div ref={cartRef} className='my-10  mt-10 items-center' style={style}>
			{stock ? (
				<Button
					{...BtnLoadingProps}
					type={isAlreadyInCert ? 'already-cart' : 'primary'}
					style={{
						display: open ? 'none' : 'block',
						height: height,
						...buttonStyle
					}}
					label={isAlreadyInCert && buttonLabel?.length > 0 ? buttonLabel?.substring(0, 10) : checkJsx(label)}
					endIcon={
						isAlreadyInCert &&
						!isLoading && (
							<IC_ARROWDROPDOWN
								fill='#0e7673'
								style={{
									color: '#0e7673',
									fill: '#0e7673',
									height: '30px',
									width: '30px',
									// marginLeft: '30px',
									display: 'flex'
								}}
							/>
						)
					}
					onClick={() => {
						if (isLogged) {
							setIsLoading(true);
							setTimeout(() => {
								setOpen(true);
								handleDropdownPosition();
							}, 1000);
						} else {
							setShowLoginModal(true);
						}
					}}
				/>
			) : (
				<Button
					lableClass={outOfStockClass}
					style={buttonStyle}
					label={
						isRequestSent || notified || checkIsRequested ? (
							<div className='d-flex items-center gap-5'>
								<IC_NOTIFIED />
							</div>
						) : (
							<span className=''>Notify</span>
						)
					}
					type='primary-outline'
					disabled={isRequestSent || notified || checkIsRequested}
					onClick={handleClick}
				/>
			)}

			{open && quantityData?.length > 0 && (
				<div className={open ? styles.selectlist : styles.close}>
					<input
						type='number'
						readOnly
						className={styles.input}
						style={{
							height: height,
							...inputStyle
						}}
						placeholder={isAlreadyInCert ? `${buttonLabel}` : 'Select Quantity'}
						onClick={() => setOpen(!open)}
					/>
					<div
						className={cls(
							styles.option_list_warp,
							open && styles.option_list_warp,
							!open && styles.close,
							positionUpwards && styles.positionUpwards
						)}>
						<div className={cls(styles.menuItems, 'd-flex justify-space-between items-center')}>
							<div className={cls('p-12 text-grey900 fw-600')}>Select Quantity</div>
							<div className='pr-10 cursor-pointer'>
								<MdClose size={20} className={styles.closeIcon} onClick={() => setOpen(false)} />
							</div>
						</div>
						{isAlreadyInCert && (
							<div
								onClick={() => {
									removeCart(variant_id, product?.p_name || productRawData?.p_name);
									setOpen(false);
								}}
								style={{
									color: '#fd6a6a'
								}}
								className={cls(styles.menuItems, 'p-10 border-b1-grey300 text-error')}>
								Remove item
							</div>
						)}
						{quantityData?.length > 0 &&
							quantityData.map((option: any, i: number) => (
								<div
									key={i}
									className={cls(styles.menuItems, isAlreadyInCert?.qty === option.value && styles.active)}
									onClick={() => {
										setOpen(false);
										handleAddToCart(parseInt(option.value));
									}}>
									<div className='d-flex justify-space-between items-center'>
										<div className='p-10'>{option.label}</div>
										{isAlreadyInCert?.qty === option.value && (
											<div className='pr-10'>
												<MdCheckCircle size={20} className='text-primary' />
											</div>
										)}
									</div>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
};

export default dynamic(() => Promise.resolve(AddToCart), { ssr: false });
